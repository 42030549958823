<template>
    <div class="testCon">
        <p>GET TOKEN</p>
        <div class="selectCon">
            <p>Select chain:</p>
            <el-select v-model="selectVal" placeholder="Select chain">
                <el-option label="CMP" value="cmp"></el-option>
                <el-option label="HECO" value="httest"></el-option>
            </el-select>
        </div>
        <div class="inputCon">
            <input type="text" name="" placeholder="Input address" v-model="address">
            <button @click="GetToken">GET</button>
        </div>
        
    </div>
</template>
<style lang="scss">
    .testCon{
        height: 600px;
        width: 800px;
        padding-top: 50px;
        margin: 0 auto;
        text-align: center;
        p{
            font-size: 50px;
            margin: 20px 0;
        }
        .selectCon{
            display: flex;
            width: 100%;
            justify-content: center;
            align-items: center;
            .el-select .el-input input{
                height: 60px;
                font-size: 30px;
            }
        }
        .inputCon{
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            margin-top: 20px;
            
            input{
                width: 80%;
                height: 60px;
                font-size: 30px;
                border-radius: 10px;
                border: 0;
                outline: none;
                padding: 10px 15px;
            }
            button{
                height: 54px;
                width: 160px;
                border: 3px solid #c822ff;
                color: #c822ff;
                font-size: 27px;
                text-align: center;
                cursor: pointer;
                font-family: Rubik-BoldItalic;
                background-color: transparent;
                margin-top: 20px;
            }
        }
        
        
    }
</style>
<script>
export default {
    data(){
        return {
            address: '',
            selectVal: 'cmp'
        }
    },

    created() {
        // let ret = this.getQueryString('data')
        // console.log(ret)
        // if(ret){
        //     ret = JSON.parse(ret)
        //     if(ret.code == 200){
        //         this.$message({
        //             message: "Got it",
        //             type: "success",
        //         });
        //         this.address = ''
        //     }else{
        //         this.$message.error(ret.err_msg);
        //     }
        // }
    },

    methods: {
        // getQueryString(name) { 
        //     return decodeURIComponent((new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(window.location.href) || [, ""])[1].replace(/\+/g, '%20')) || null
        // },
        async GetToken() {
            if (!this.address) return;
            // window.location.href = `http://52.68.26.212:8070/api/meta-faucet/v1/login/github?key=${this.address}-${this.selectVal}`
            const res = await this.$axios.post(`api/meta-faucet/v1/tokentransfer`, {
                to: this.address,
                network: this.selectVal
            });
            console.log(res)
            let { status, statusText, data } = res;
            console.log(status)
            if (status === 200) {
                if(data.error == 200){
                    this.$message({
                        message: "Got it",
                        type: "success",
                    });
                    this.address = ''
                }else{
                    this.$message.error(data.err_msg || data.data);
                }
            }else{
                this.$message.error(statusText);
            }
        }
    }
}
</script>